import React, { useEffect } from "react";
import { useAuth } from "../services/hooks/useAuth";
import { useRedirect } from "../services/hooks/useRedirect";
import Loader from "../components/Loader";

export default function Home() {
  const { user } = useAuth(true, undefined, "/dashboard");
  const { redirect } = useRedirect();

  useEffect(() => {
    if (user) {
      redirect("/dashboard");
    }
  }, [user]);

  return (
    <React.Fragment>
      <Loader absoluteCenter />
    </React.Fragment>
  );
}
